import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Space, Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import GA from '../../../analytics/ga';
import MakePaymentButton from './MakePaymentButton';
import MakePaymentWithNMIButton from './MakePaymentWithNMIButton';
import MakePaymentWithPayPalButton from './MakePaymentWithPayPalButton';
import MakePaymentWithCryptoButton from './Crypto/MakePaymentWithCryptoButton';

export const MIN_AMOUNT = 10;

export default function AddCredits({ defaultValue = 25, loading, region, onMakePayment, hidePayPal, crypto, creditCardButtonTitle, showIcon = true, riskLevel = 0, emailVerified = false, source, callback, onPaymentMade, secondaryTab, minValue = MIN_AMOUNT }) {
  const [amount, setAmount] = useState(Math.max(defaultValue, minValue));
  const [waiting, setWaiting] = useState(false);
  const internalLoading = waiting || loading;
  const { t } = useTranslation();

  useEffect(() => {
    if (minValue > amount) {
      setAmount(minValue);
    }
  }, [minValue]);

  var maxValue = 10000;
  if (riskLevel === 2) {
    maxValue = 50;
  } else if (riskLevel === 3) {
    maxValue = 25;
  }

  function handleOnMakePayment() {
    GA.track('button', source ? `Payment intent from ${source}` : 'Payment intent', 'Card');

    if (callback) {
      callback();
    }

    if (secondaryTab) {
      window.open(`/profile?deposit=${amount}&secondary_tab=true`, '_blank', 'noopener,noreferrer');
    }

    if (onMakePayment) {
      onMakePayment(amount, region);
    }
  }

  function handleSetAmount(value) {
    setAmount(Number(value) || minValue);
  }

  function handleOnPaymentMade() {
    if (callback) {
      callback();
    }
    if (onPaymentMade) {
      onPaymentMade();
    }
  }

  async function delayedCallback() {
    if (callback) {
      setWaiting(true);
      await new Promise(r => setTimeout(r, 10000));
      setWaiting(false);
      callback();
    }
  }

  return <Space style={{ alignItems: 'flex-start', marginBottom: '-30px' }}>
    {!hidePayPal && <div style={{ marginTop: '44px' }}><Trans i18nKey='title.add'>Add</Trans></div>}
    <InputNumber
      style={{ marginTop: '40px' }}
      formatter={value => `$ ${value}`}
      value={amount}
      min={minValue}
      max={internalLoading ? amount: maxValue}
      step={50}
      onChange={handleSetAmount}
      onPressEnter={handleOnMakePayment}
    />
    {' '}
    <Space direction='vertical'>
      <Tooltip placement='top' title={<>
        {!emailVerified ?
          <Trans i18nKey='title.email_unverified'>Please Verify Your Email Address</Trans>
          :
          <>{riskLevel > 1 ?
            <>Your account is currently flagged with a high-risk status for using this</>
            :
            <><Trans i18nKey='title.fee'>Fee</Trans>: 2.3%</>
          }</>
        }
      </>}>
        {''}
        <MakePaymentWithNMIButton
          showIcon
          title={creditCardButtonTitle}
          amount={amount}
          loading={internalLoading}
          disabled={riskLevel > 1 || !emailVerified}
          riskLevel={riskLevel}
          callback={handleOnPaymentMade}
        />
      </Tooltip>
      <Tooltip placement='top' title={<>
        {!emailVerified ?
          <Trans i18nKey='title.email_unverified'>Please Verify Your Email Address</Trans>
          :
          <>{riskLevel > 2 ?
            <>Your account is currently flagged with a high-risk status for using this</>
            :
            <><Trans i18nKey='title.fee'>Fee</Trans>: 2.9%</>
          }</>
        }
      </>}>
        {''}
        <MakePaymentButton
          showIcon={showIcon}
          title={creditCardButtonTitle}
          amount={amount}
          loading={internalLoading}
          disabled={riskLevel > 2 || !emailVerified}
          onMakePayment={handleOnMakePayment}
        />
      </Tooltip>
      {!hidePayPal && crypto && <><Tooltip title={<>
        <Trans i18nKey='title.bonus'>Bonus</Trans>: 4%, <Trans i18nKey='title.noRefund'>No refund</Trans>
      </>} placement='bottom'>{''}<MakePaymentWithCryptoButton loading={internalLoading} amount={amount} source={source} callback={delayedCallback} secondaryTab={secondaryTab}/></Tooltip></>}
    </Space>
    {!hidePayPal && riskLevel < 4 && <span><Tooltip title={t('title.fee', 'Fee') + ': 3.9%'} placement='top'>{''}
      <MakePaymentWithPayPalButton amount={amount} loading={internalLoading} source={source} callback={delayedCallback}/>
    </Tooltip></span>}
  </Space>;
}

AddCredits.propTypes = {
  loading: PropTypes.bool,
  region: PropTypes.string,
  onMakePayment: PropTypes.func,
  onPaymentMade: PropTypes.func,
  callback: PropTypes.func,
  hidePayPal: PropTypes.bool,
  crypto: PropTypes.bool,
  creditCardButtonTitle: PropTypes.string,
  defaultValue: PropTypes.number,
  showIcon: PropTypes.bool,
  riskLevel: PropTypes.number,
  emailVerified: PropTypes.bool,
  personal: PropTypes.bool,
  source: PropTypes.string,
  secondaryTab: PropTypes.bool,
  minValue: PropTypes.number,
};
